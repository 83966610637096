<template>
  <div class="modal">
    <div class="modal__mask">
      <div class="modal__wrapper">
        <div class="modal__container">
          <div class="modal__body">
            <slot name="body"></slot>
          </div>
          <div class="modal__buttons">
            <slot name="buttons"></slot>
          </div>
          <button class="close" @click="$emit('close')"><img src="@/assets/close.svg" alt=""></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  &__mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  &__wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &__container {
    width: 464px;
    margin: 0px auto;
    padding: 72px 64px 56px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    position: relative;
    @media only screen and (max-width: 600px) {
      width: 351px;
    }
  }

  &__body {
    font-family: 'SamsungOne';
    font-size: 24px;
    font-weight: bold;
    color: #000;
    line-height: 1.33;
  }

  &__buttons {
    margin-top: 32px;

    ::v-deep button {
      width: 147px;
      margin: 0 4.5px;
      line-height: 38px;
      border-radius: 24px;
      border: 1px solid #4e4e4e;
      font-size: 14px;
      font-weight: bold;
      color: #4e4e4e;

      &.button--confirm {
        color: #fff;
        background-color: #1428a0;
        line-height: 40px;
        border: 0;
      }
    }

    @media only screen and (max-width: 600px) {
      margin-left: -45px;
      margin-right: -45px;
    }
  }

  .close {
    position: absolute;
    top: 32px;
    right: 48px;
    @media only screen and (max-width: 600px) {
      top: 18px;
      right: 24px;
    }
  }
}
</style>
